import * as FormatCurrency from "~/utils/currency";

type FormatCurrencyParameters = Omit<
  Parameters<(typeof FormatCurrency)["formatCurrency"]>[number],
  "locale"
>;

type FormatCurrencyRangeParameters = Omit<
  Parameters<(typeof FormatCurrency)["formatCurrencyRange"]>[number],
  "locale"
>;

/**
 * Convenient wrapper around the formatCurrency function from the currency utils.
 * Automatically sets the locale to the current locale.
 */
export const useFormatCurrency = () => {
  const { locale } = useI18n();

  const formatCurrency = (params: FormatCurrencyParameters) => {
    return FormatCurrency.formatCurrency({
      ...params,
      locale: locale.value,
    });
  };

  const formatCurrencyRange = (params: FormatCurrencyRangeParameters) => {
    return FormatCurrency.formatCurrencyRange({
      ...params,
      locale: locale.value,
    });
  };

  return {
    formatCurrency,
    formatCurrencyRange,
  };
};
