export const formatCurrency = ({
  amount,
  currency,
  locale,
  unit,
}: {
  amount: number;
  currency: string;
  locale: Intl.LocalesArgument;
  unit?: Intl.NumberFormatOptions["unit"];
}) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    unit,
  }).format(amount);
};

export const formatCurrencyRange = ({
  range: { start, stop },
  locale,
}: {
  range: {
    start?: { amount: number; currency: string } | null;
    stop?: { amount: number; currency: string } | null;
  };
  locale?: Intl.LocalesArgument;
}) => {
  const startMoney =
    start && formatCurrency({ amount: start.amount, currency: start.currency, locale });
  const stopMoney =
    stop && formatCurrency({ amount: stop.amount, currency: stop.currency, locale });

  if (startMoney === stopMoney) {
    return startMoney;
  }

  return `${startMoney} - ${stopMoney}`;
};
