<template>
  <HoverCardRoot v-model:open="open">
    <HoverCardTrigger as-child>
      <slot name="trigger" :items-count="itemsCount" :checkout-id="checkoutIdCookie" />
    </HoverCardTrigger>
    <HoverCardPortal>
      <!-- class=" w-[300px] rounded-md bg-white p-5 shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] " -->
      <HoverCardContent
        :side-offset="5"
        as-child
        class="z-20 min-w-[300px] data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade data-[state=open]:transition-all"
      >
        <CartPreview v-if="!loading" :checkout="result?.checkout" @delete-line="deleteLines" />
        <HoverCardArrow class="fill-white" :width="8" />
      </HoverCardContent>
    </HoverCardPortal>
  </HoverCardRoot>
</template>
<script setup lang="ts">
import { CartPreviewDocument } from "@graphql";
import {
  HoverCardArrow,
  HoverCardContent,
  HoverCardPortal,
  HoverCardRoot,
  HoverCardTrigger,
} from "radix-vue";
import { ref } from "vue";

import CartPreview from "./CartPreview.vue";
const open = ref(false);

const { checkoutIdCookie, deleteLines } = useCheckout();

const { result, loading } = useQuery(
  CartPreviewDocument,
  {
    id: checkoutIdCookie.value!,
  },
  {
    enabled: computed<boolean>(() => !!checkoutIdCookie.value),
  }
);

const itemsCount = computed<number>(
  () =>
    result.value?.checkout?.lines.reduce<number>((acc, line) => {
      acc += line.quantity;
      return acc;
    }, 0) ?? 0
);
</script>
