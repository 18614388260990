import { default as indexntzJNtxNjbMeta } from "/app/pages/(auth)/account-confirm/index.vue?macro=true";
import { default as indexMivftKvBaGMeta } from "/app/pages/(auth)/login/index.vue?macro=true";
import { default as indexcCxEaBPsUFMeta } from "/app/pages/(auth)/register/index.vue?macro=true";
import { default as playgroundJJ5fXSBmGNMeta } from "/app/pages/(dev)/playground.vue?macro=true";
import { default as typographyLmsZcHpd1AMeta } from "/app/pages/(dev)/typography.vue?macro=true";
import { default as indexNqIgPxbtepMeta } from "/app/pages/(products)/categories/[categorySlug]/[productSlug]/index.vue?macro=true";
import { default as index0coWt94GbEMeta } from "/app/pages/(products)/categories/[categorySlug]/index.vue?macro=true";
import { default as index2Mqezjia3yMeta } from "/app/pages/(products)/categories/index.vue?macro=true";
import { default as index7ZtduOIoVXMeta } from "/app/pages/checkout/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexG1Ehu1sqkUMeta } from "/app/pages/orders/index.vue?macro=true";
import { default as addressesp9PeR2maRxMeta } from "/app/pages/profile/addresses.vue?macro=true";
import { default as gift_45cardscvz8Q3haqsMeta } from "/app/pages/profile/gift-cards.vue?macro=true";
import { default as ordersTvQzfKkQSJMeta } from "/app/pages/profile/orders.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account-confirm___en",
    path: "/account-confirm",
    component: () => import("/app/pages/(auth)/account-confirm/index.vue")
  },
  {
    name: "account-confirm___fr",
    path: "/fr/account-confirm",
    component: () => import("/app/pages/(auth)/account-confirm/index.vue")
  },
  {
    name: "account-confirm___de",
    path: "/de/account-confirm",
    component: () => import("/app/pages/(auth)/account-confirm/index.vue")
  },
  {
    name: "account-confirm___pl",
    path: "/pl/account-confirm",
    component: () => import("/app/pages/(auth)/account-confirm/index.vue")
  },
  {
    name: "account-confirm___es",
    path: "/es/account-confirm",
    component: () => import("/app/pages/(auth)/account-confirm/index.vue")
  },
  {
    name: "login___en",
    path: "/login",
    component: () => import("/app/pages/(auth)/login/index.vue")
  },
  {
    name: "login___fr",
    path: "/fr/login",
    component: () => import("/app/pages/(auth)/login/index.vue")
  },
  {
    name: "login___de",
    path: "/de/login",
    component: () => import("/app/pages/(auth)/login/index.vue")
  },
  {
    name: "login___pl",
    path: "/pl/login",
    component: () => import("/app/pages/(auth)/login/index.vue")
  },
  {
    name: "login___es",
    path: "/es/login",
    component: () => import("/app/pages/(auth)/login/index.vue")
  },
  {
    name: "register___en",
    path: "/register",
    component: () => import("/app/pages/(auth)/register/index.vue")
  },
  {
    name: "register___fr",
    path: "/fr/register",
    component: () => import("/app/pages/(auth)/register/index.vue")
  },
  {
    name: "register___de",
    path: "/de/register",
    component: () => import("/app/pages/(auth)/register/index.vue")
  },
  {
    name: "register___pl",
    path: "/pl/register",
    component: () => import("/app/pages/(auth)/register/index.vue")
  },
  {
    name: "register___es",
    path: "/es/register",
    component: () => import("/app/pages/(auth)/register/index.vue")
  },
  {
    name: "playground___en",
    path: "/playground",
    meta: playgroundJJ5fXSBmGNMeta || {},
    component: () => import("/app/pages/(dev)/playground.vue")
  },
  {
    name: "playground___fr",
    path: "/fr/playground",
    meta: playgroundJJ5fXSBmGNMeta || {},
    component: () => import("/app/pages/(dev)/playground.vue")
  },
  {
    name: "playground___de",
    path: "/de/playground",
    meta: playgroundJJ5fXSBmGNMeta || {},
    component: () => import("/app/pages/(dev)/playground.vue")
  },
  {
    name: "playground___pl",
    path: "/pl/playground",
    meta: playgroundJJ5fXSBmGNMeta || {},
    component: () => import("/app/pages/(dev)/playground.vue")
  },
  {
    name: "playground___es",
    path: "/es/playground",
    meta: playgroundJJ5fXSBmGNMeta || {},
    component: () => import("/app/pages/(dev)/playground.vue")
  },
  {
    name: "typography___en",
    path: "/typography",
    meta: typographyLmsZcHpd1AMeta || {},
    component: () => import("/app/pages/(dev)/typography.vue")
  },
  {
    name: "typography___fr",
    path: "/fr/typography",
    meta: typographyLmsZcHpd1AMeta || {},
    component: () => import("/app/pages/(dev)/typography.vue")
  },
  {
    name: "typography___de",
    path: "/de/typography",
    meta: typographyLmsZcHpd1AMeta || {},
    component: () => import("/app/pages/(dev)/typography.vue")
  },
  {
    name: "typography___pl",
    path: "/pl/typography",
    meta: typographyLmsZcHpd1AMeta || {},
    component: () => import("/app/pages/(dev)/typography.vue")
  },
  {
    name: "typography___es",
    path: "/es/typography",
    meta: typographyLmsZcHpd1AMeta || {},
    component: () => import("/app/pages/(dev)/typography.vue")
  },
  {
    name: "categories-categorySlug-productSlug___en",
    path: "/categories/:categorySlug()/:productSlug()",
    component: () => import("/app/pages/(products)/categories/[categorySlug]/[productSlug]/index.vue")
  },
  {
    name: "categories-categorySlug-productSlug___fr",
    path: "/fr/categories/:categorySlug()/:productSlug()",
    component: () => import("/app/pages/(products)/categories/[categorySlug]/[productSlug]/index.vue")
  },
  {
    name: "categories-categorySlug-productSlug___de",
    path: "/de/categories/:categorySlug()/:productSlug()",
    component: () => import("/app/pages/(products)/categories/[categorySlug]/[productSlug]/index.vue")
  },
  {
    name: "categories-categorySlug-productSlug___pl",
    path: "/pl/categories/:categorySlug()/:productSlug()",
    component: () => import("/app/pages/(products)/categories/[categorySlug]/[productSlug]/index.vue")
  },
  {
    name: "categories-categorySlug-productSlug___es",
    path: "/es/categories/:categorySlug()/:productSlug()",
    component: () => import("/app/pages/(products)/categories/[categorySlug]/[productSlug]/index.vue")
  },
  {
    name: "categories-categorySlug___en",
    path: "/categories/:categorySlug()",
    component: () => import("/app/pages/(products)/categories/[categorySlug]/index.vue")
  },
  {
    name: "categories-categorySlug___fr",
    path: "/fr/categories/:categorySlug()",
    component: () => import("/app/pages/(products)/categories/[categorySlug]/index.vue")
  },
  {
    name: "categories-categorySlug___de",
    path: "/de/categories/:categorySlug()",
    component: () => import("/app/pages/(products)/categories/[categorySlug]/index.vue")
  },
  {
    name: "categories-categorySlug___pl",
    path: "/pl/categories/:categorySlug()",
    component: () => import("/app/pages/(products)/categories/[categorySlug]/index.vue")
  },
  {
    name: "categories-categorySlug___es",
    path: "/es/categories/:categorySlug()",
    component: () => import("/app/pages/(products)/categories/[categorySlug]/index.vue")
  },
  {
    name: "categories___en",
    path: "/categories",
    component: () => import("/app/pages/(products)/categories/index.vue")
  },
  {
    name: "categories___fr",
    path: "/fr/categories",
    component: () => import("/app/pages/(products)/categories/index.vue")
  },
  {
    name: "categories___de",
    path: "/de/categories",
    component: () => import("/app/pages/(products)/categories/index.vue")
  },
  {
    name: "categories___pl",
    path: "/pl/categories",
    component: () => import("/app/pages/(products)/categories/index.vue")
  },
  {
    name: "categories___es",
    path: "/es/categories",
    component: () => import("/app/pages/(products)/categories/index.vue")
  },
  {
    name: "checkout___en",
    path: "/checkout",
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "checkout___fr",
    path: "/fr/checkout",
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "checkout___de",
    path: "/de/checkout",
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "checkout___pl",
    path: "/pl/checkout",
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "checkout___es",
    path: "/es/checkout",
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___pl",
    path: "/pl",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "orders___en",
    path: "/orders",
    component: () => import("/app/pages/orders/index.vue")
  },
  {
    name: "orders___fr",
    path: "/fr/orders",
    component: () => import("/app/pages/orders/index.vue")
  },
  {
    name: "orders___de",
    path: "/de/orders",
    component: () => import("/app/pages/orders/index.vue")
  },
  {
    name: "orders___pl",
    path: "/pl/orders",
    component: () => import("/app/pages/orders/index.vue")
  },
  {
    name: "orders___es",
    path: "/es/orders",
    component: () => import("/app/pages/orders/index.vue")
  },
  {
    name: "profile___en",
    path: "/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue"),
    children: [
  {
    name: "profile-addresses___en",
    path: "addresses",
    component: () => import("/app/pages/profile/addresses.vue")
  },
  {
    name: "profile-gift-cards___en",
    path: "gift-cards",
    component: () => import("/app/pages/profile/gift-cards.vue")
  },
  {
    name: "profile-orders___en",
    path: "orders",
    component: () => import("/app/pages/profile/orders.vue")
  }
]
  },
  {
    name: "profile___fr",
    path: "/fr/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue"),
    children: [
  {
    name: "profile-addresses___fr",
    path: "addresses",
    component: () => import("/app/pages/profile/addresses.vue")
  },
  {
    name: "profile-gift-cards___fr",
    path: "gift-cards",
    component: () => import("/app/pages/profile/gift-cards.vue")
  },
  {
    name: "profile-orders___fr",
    path: "orders",
    component: () => import("/app/pages/profile/orders.vue")
  }
]
  },
  {
    name: "profile___de",
    path: "/de/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue"),
    children: [
  {
    name: "profile-addresses___de",
    path: "addresses",
    component: () => import("/app/pages/profile/addresses.vue")
  },
  {
    name: "profile-gift-cards___de",
    path: "gift-cards",
    component: () => import("/app/pages/profile/gift-cards.vue")
  },
  {
    name: "profile-orders___de",
    path: "orders",
    component: () => import("/app/pages/profile/orders.vue")
  }
]
  },
  {
    name: "profile___pl",
    path: "/pl/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue"),
    children: [
  {
    name: "profile-addresses___pl",
    path: "addresses",
    component: () => import("/app/pages/profile/addresses.vue")
  },
  {
    name: "profile-gift-cards___pl",
    path: "gift-cards",
    component: () => import("/app/pages/profile/gift-cards.vue")
  },
  {
    name: "profile-orders___pl",
    path: "orders",
    component: () => import("/app/pages/profile/orders.vue")
  }
]
  },
  {
    name: "profile___es",
    path: "/es/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue"),
    children: [
  {
    name: "profile-addresses___es",
    path: "addresses",
    component: () => import("/app/pages/profile/addresses.vue")
  },
  {
    name: "profile-gift-cards___es",
    path: "gift-cards",
    component: () => import("/app/pages/profile/gift-cards.vue")
  },
  {
    name: "profile-orders___es",
    path: "orders",
    component: () => import("/app/pages/profile/orders.vue")
  }
]
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr-FR-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de-DE-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/pl-PL-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es-BR-sitemap.xml",
    component: component_45stubnO9MU04yTU
  }
]