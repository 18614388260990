<template>
  <Separator
    :class="
      twMerge(
        'bg-gray3 data-[orientation=horizontal]:h-px data-[orientation=vertical]:h-full data-[orientation=horizontal]:w-full data-[orientation=vertical]:w-px',
        props.class
      )
    "
    v-bind="$props"
  />
</template>
<script setup lang="ts">
import type { SeparatorProps } from "radix-vue";
import { Separator } from "radix-vue";
import { twMerge } from "tailwind-merge";

const props = defineProps<SeparatorProps & { class?: string }>();
</script>
