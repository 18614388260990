<template>
  <Header :key="locale" />
  <MainMenu :navigation="data?.navigation" class="sticky top-16 min-h-[49px]" />
  <div class="flex min-h-[calc(100dvh-105px)] flex-col">
    <main
      class="md:mb-18 m-auto mb-6 flex w-full max-w-7xl flex-1 flex-col items-center justify-center gap-6 px-3 py-3 sm:mb-12 md:px-6"
    >
      <h1 v-if="error?.statusCode === 404" class="mt-4 text-center">
        {{ errorMessage }}
      </h1>
      <NuxtLinkLocale href="/" class="as-h3 rounded-lg border-2 border-primary px-3 py-2">
        {{ $t("error.backToHome") }}
      </NuxtLinkLocale>
    </main>
    <Footer :key="locale" :footer="data?.footer" />
  </div>
</template>
<script setup lang="ts">
import type { NuxtError } from "#app";

import { DefaultLayoutDocument } from "./src/api/generated/graphql";

const { error } = defineProps({
  error: Object as () => NuxtError,
});

const { locale, t } = useI18n();

const { result: data } = useQuery(DefaultLayoutDocument, {
  languageCode: toLanguageCode(locale.value),
});

const errorMessage = computed(() => {
  if (error?.statusCode === 404) {
    return t("error.404");
  }

  return t("error.somethingWentWrong");
});
</script>
