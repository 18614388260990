<template>
  <Select :options="options" :default-value="currentLocale" @update:model-value="onSetLocale">
    <template #trigger-prefix>
      <Icon :icon="`flag:${channel}-4x3`" />
    </template>
  </Select>
</template>
<script setup lang="ts">
import { Icon } from "@iconify/vue";
const { t, locale: currentLocale, locales, setLocale } = useI18n();
const { channel } = useChannel();

const onSetLocale = (value: string) => {
  setLocale(value);
  refreshNuxtData();
};

const options = computed(() =>
  locales.value.map((locale) => ({
    value: locale.code,
    icon: locale.channel ?? locale.code,
    label: t("header.countryDisplay", 1, {
      locale: locale.code,
    }),
  }))
);
</script>
