<template>
  <header :key="locale" class="sticky top-0 z-20 min-h-[56px] bg-white backdrop-blur-md">
    <div class="mx-auto max-w-7xl px-3 sm:px-6">
      <div class="flex h-16 items-center justify-between gap-4 md:gap-8">
        <NuxtLinkLocale href="/" aria-label="Genotic main page">
          <NuxtImg src="/logo.svg" alt="Genotic logo" class="h-10 min-w-[88px]" />
        </NuxtLinkLocale>

        <!-- TODO: readd search when algolia is added-->
        <!-- <Input class="w-full md:max-w-[450px]" name="search"> -->
        <!-- <template #suffix> -->
        <!--   <Icon icon="radix-icons:magnifying-glass" /> -->
        <!-- </template> -->
        <!-- </Input> -->
        <div class="flex items-center gap-1">
          <GlobalSearch class="mr-6" />
          <LangSelect />
          <Separator orientation="vertical" decorative />
          <template v-if="isAuthenticated">
            <!-- <button class="flex items-center justify-center rounded p-2 hover:bg-mauve3"> -->
            <!--   <Icon icon="lucide:user" class="mr-2 inline-block" /> -->
            <!-- </button> -->
            <NuxtLinkLocale
              to="/profile"
              class="flex items-center justify-center gap-2 text-nowrap rounded px-3 py-2 hover:bg-mauve3"
            >
              <Icon icon="lucide:user" class="inline-block h-5 w-5" />
              <span class="hidden md:inline">
                {{ $t("header.myAccount") }}
              </span>
            </NuxtLinkLocale>
            <!-- <button class="flex items-center justify-center rounded p-2 hover:bg-mauve3"> -->
            <!--   <Icon icon="lucide:shopping-cart" class="font-size mr-2 inline-block" /> -->
            <!--   {{ t("header.cart") }} -->
            <!-- </button> -->

            <button class="text-nowrap rounded p-2 hover:bg-mauve3" @click="onSignOut">
              {{ $t("auth.logout") }}
            </button>
          </template>

          <CartOverlay>
            <template #trigger="{ itemsCount, checkoutId }">
              <NuxtLinkLocale
                :to="`/checkout?checkoutId=${checkoutId}`"
                class="relative flex items-center justify-center gap-2 text-nowrap rounded px-3 py-2 hover:bg-mauve3"
              >
                <div
                  v-if="itemsCount"
                  class="absolute left-0 top-0 h-3 w-3 rounded-full bg-primary"
                />
                <Icon icon="lucide:shopping-cart" class="font-size h-5 w-5" />

                <span class="hidden md:inline">
                  {{ $t("header.cart") }}
                </span>
              </NuxtLinkLocale>
            </template>
          </CartOverlay>
          <template v-if="!isAuthenticated">
            <NuxtLinkLocale to="/login" class="text-nowrap rounded px-3 py-2 hover:bg-mauve3">
              {{ $t("auth.login") }}
            </NuxtLinkLocale>
            <NuxtLinkLocale to="/register" class="text-nowrap rounded px-3 py-2 hover:bg-mauve3">
              {{ $t("auth.register") }}
            </NuxtLinkLocale>
          </template>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue";

import { CartOverlay } from "~/src/cart/components";

// TODO: check if locale is still needed
const { locale } = useI18n();
const { isAuthenticated, signOut } = useAuth();

const onSignOut = async () => {
  signOut();
  await navigateTo("/");
};
</script>
