import type { ApolloClient } from "@apollo/client/core";
import { ApolloLink, from } from "@apollo/client/core";
import type { TokenRefreshMutation, TokenRefreshMutationVariables } from "@graphql";
import { provideApolloClient } from "@vue/apollo-composable";

import { SALEOR_ACCESS_TOKEN_NAME, SALEOR_REFRESH_TOKEN_NAME } from "~/constants/saleor";

export default defineNuxtPlugin(({ hook }) => {
  const { clients } = useApollo();

  const defaultClient: ApolloClient<unknown> = clients!.default;

  const accessTokenCookie = useCookie(SALEOR_ACCESS_TOKEN_NAME);
  const refreshTokenCookie = useCookie(SALEOR_REFRESH_TOKEN_NAME);

  const authLink = new ApolloLink((operation, forward) => {
    if (accessTokenCookie.value && tryGetExpFromJwt(accessTokenCookie.value)) {
      return forward(operation).map((data) => data);
    }

    if (refreshTokenCookie.value && !tryGetExpFromJwt(refreshTokenCookie.value)) {
      operation.setContext(async () => {
        const res = await fetchGql<TokenRefreshMutation, TokenRefreshMutationVariables>({
          query: `mutation TokenRefresh($csrfToken: String, $refreshToken: String) {
                    tokenRefresh(csrfToken: $csrfToken, refreshToken: $refreshToken) {
                      token
                      errors {
                        field
                        message
                      }
                    }
                  }
                `,
          variables: {
            refreshToken: refreshTokenCookie.value,
          },
        });

        if (!res.tokenRefresh?.errors.length) {
          accessTokenCookie.value = res.tokenRefresh?.token;
        }
      });
    }

    return forward(operation).map((data) => data);
  });

  defaultClient.setLink(from([authLink, defaultClient.link]));

  provideApolloClient(defaultClient);

  hook("apollo:error", (error) => {
    // eslint-disable-next-line no-console
    console.log("error: ", error);
  });
});
