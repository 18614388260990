import type { CheckoutDetailsFragment } from "@graphql";
import {
  CheckoutAddLineDocument,
  CheckoutCreateDocument,
  CheckoutDeleteLinesDocument,
  CheckoutFindDocument,
} from "@graphql";
import { namedOperations } from "@operations";

type UseCheckoutOptions = {
  /**
   * by default it gets value from route.params.channel, but can be changed
   */
  channel?: string;
};

export const useCheckout = (options?: UseCheckoutOptions) => {
  const { client } = useApolloClient();
  const config = useRuntimeConfig();
  const { locale, locales } = useI18n();

  const currentLocale = computed(() => locales.value.find((l) => l.code === locale.value));

  const channel = options?.channel ?? currentLocale.value?.channel ?? currentLocale.value?.code;

  const cookieName = `checkoutId-${channel}`;

  const checkoutIdCookie = useCookie(cookieName, {
    sameSite: "lax",
    secure: config.public.STOREFRONT_URL.startsWith("https"),
  });

  const find = async (checkoutId?: string): Promise<CheckoutDetailsFragment | null | undefined> => {
    if (!checkoutId) {
      checkoutId = checkoutIdCookie.value || "";
    }
    try {
      if (!checkoutId) {
        return null;
      }

      const { data } = await client.query({
        query: CheckoutFindDocument,
        variables: { id: checkoutId },
        fetchPolicy: "no-cache",
      });

      return data.checkout;
      // eslint-disable-next-line no-empty
    } catch {}
  };

  const findOrCreate = async (
    checkoutId?: string | null
  ): Promise<CheckoutDetailsFragment | null | undefined> => {
    if (!checkoutId) {
      return await create();
    }

    const checkout = await find(checkoutId);

    return checkout || (await create());
  };

  const create = async (): Promise<CheckoutDetailsFragment | null | undefined> => {
    const { data } = await client.mutate({
      mutation: CheckoutCreateDocument,
      variables: {
        channel,
      },
    });

    return data?.checkoutCreate?.checkout;
  };

  const addLine = async ({
    productVariantId,
    quantity,
  }: {
    productVariantId: string;
    quantity?: number;
  }): Promise<void> => {
    const checkout = await findOrCreate(checkoutIdCookie.value);

    if (!checkout) {
      throw new Error("No checkout, this should never happen");
    }

    checkoutIdCookie.value = checkout.id;

    await client.mutate({
      mutation: CheckoutAddLineDocument,
      variables: {
        id: checkout.id,
        productVariantId,
        quantity,
      },
      fetchPolicy: "no-cache",
      refetchQueries: [namedOperations.Query.CartPreview],
    });
  };

  const deleteLines = async (lineIds: string | string[]): Promise<void> => {
    const checkoutId = checkoutIdCookie.value;

    if (!checkoutId) {
      throw new Error("No checkout, this should never happen");
    }

    await client.mutate({
      mutation: CheckoutDeleteLinesDocument,
      variables: {
        checkoutId,
        lineIds,
      },
      refetchQueries: [namedOperations.Query.CartPreview],
    });
  };

  return {
    checkoutIdCookie,
    create,
    addLine,
    find,
    findOrCreate,
    deleteLines,
  };
};
