<template>
  <SelectRoot v-model="value" :default-value="props.defaultValue">
    <SelectTrigger
      :class="
        twMerge(
          'inline-flex h-[35px] items-center justify-between gap-[5px] rounded bg-white px-[15px] text-[13px] leading-none outline-none hover:bg-mauve3 focus:shadow-[0_0_0_2px] focus:shadow-black',
          triggerClass
        )
      "
      aria-label="Customize options"
    >
      <slot name="trigger-prefix" />
      <SelectValue :placeholder="placeholder" />
      <Icon icon="radix-icons:chevron-down" class="h-3.5 w-3.5" />
    </SelectTrigger>

    <SelectPortal>
      <SelectContent
        class="z-[100] rounded bg-white shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade"
        :side-offset="5"
      >
        <SelectViewport class="p-[5px]">
          <SelectLabel v-if="label" class="px-[25px] text-xs leading-[25px] text-mauve11">
            {{ label }}
          </SelectLabel>
          <SelectGroup>
            <SelectItem
              v-for="option in options"
              :key="option.value"
              class="relative flex h-[25px] select-none items-center rounded-[3px] pl-[25px] pr-[35px] text-[13px] leading-none data-[disabled]:pointer-events-none data-[highlighted]:bg-text-main data-[disabled]:text-mauve8 data-[highlighted]:text-primary-lightest data-[highlighted]:outline-none"
              :value="option.value"
              :disabled="option.disabled"
            >
              <SelectItemIndicator
                class="absolute left-0 inline-flex w-[25px] items-center justify-center"
              >
                <Icon icon="radix-icons:check" />
              </SelectItemIndicator>
              <SelectItemText>
                {{ option?.label ?? option?.value }}
              </SelectItemText>
            </SelectItem>
          </SelectGroup>
        </SelectViewport>
      </SelectContent>
    </SelectPortal>
  </SelectRoot>
</template>

<script setup lang="ts" generic="T extends string">
import { Icon } from "@iconify/vue";
import {
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectPortal,
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from "radix-vue";
import { twMerge } from "tailwind-merge";

const props = defineProps<{
  placeholder?: string;
  label?: string;
  value?: T;
  defaultValue?: T;
  options: { value: T; label?: string; disabled?: boolean }[];
  triggerClass?: string;
}>();

const value = ref<T | undefined>(props.value);
</script>
