import { RegisterUserDocument, TokenCreateDocument } from "@graphql";

import { SALEOR_ACCESS_TOKEN_NAME, SALEOR_REFRESH_TOKEN_NAME } from "~/constants/saleor";
import type { RegisterUserSchema, SignInSchema } from "~/src/user/schemas";
import type { PartialBy } from "~/types";
import { tryGetExpFromJwt } from "~/utils/jwt";

export const useAuth = () => {
  const config = useRuntimeConfig();
  const { client } = useApolloClient();
  const accessTokenCookie = useCookie(SALEOR_ACCESS_TOKEN_NAME);
  const refreshTokenCookie = useCookie(SALEOR_REFRESH_TOKEN_NAME);
  const { onLogout } = useApollo();

  const signIn = async (credentials: SignInSchema): Promise<void> => {
    const res = await client.mutate({
      mutation: TokenCreateDocument,
      variables: credentials,
      fetchPolicy: "no-cache",
    });

    const errors = res.errors ?? res.data?.tokenCreate?.errors;

    if (errors?.length) {
      throw new Error("Sign in failed", {
        cause: errors,
      });
    }

    const accessToken = res.data?.tokenCreate?.token;
    const refreshToken = res.data?.tokenCreate?.refreshToken;

    if (accessToken) {
      accessTokenCookie.value = accessToken;
    }

    if (refreshToken) {
      refreshTokenCookie.value = refreshToken;
    }
  };

  const register = async (
    credentials: PartialBy<RegisterUserSchema, "password2">
  ): Promise<void> => {
    delete credentials.password2;

    const res = await client.mutate({
      mutation: RegisterUserDocument,
      variables: {
        input: {
          ...credentials,
          redirectUrl: `${config.public.STOREFRONT_URL}/confirm-email`,
        },
      },
      fetchPolicy: "no-cache",
    });

    const errors = res.errors ?? res.data?.accountRegister?.errors;

    if (!errors?.length) {
      throw new Error("Sign up failed", {
        cause: res.errors,
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const confirmAccount = async (): Promise<void> => {};

  const signOut = (): void => {
    accessTokenCookie.value = undefined;
    refreshTokenCookie.value = undefined;
    onLogout();
  };

  const isAuthenticated = computed<boolean>(() => {
    const accessToken = accessTokenCookie.value;

    if (accessToken && tryGetExpFromJwt(accessToken)) {
      return true;
    }

    const refreshToken = refreshTokenCookie.value;

    if (refreshToken && tryGetExpFromJwt(refreshToken)) {
      return true;
    }

    return false;
  });

  return { signIn, isAuthenticated, signOut, register };
};
