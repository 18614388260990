import { LanguageCodeEnum } from "@graphql";

export const toLanguageCode = (str: string): LanguageCodeEnum => {
  const transformedString = (str.charAt(0).toUpperCase() +
    str.slice(1)) as keyof typeof LanguageCodeEnum;

  // it might return undefined when str doesn't match key
  const code = LanguageCodeEnum[transformedString];

  if (!code) {
    return LanguageCodeEnum["En"];
  }

  return code;
};
