<template>
  <div class="max-w-[380px] rounded-bl-lg rounded-br-lg border bg-white p-5">
    <div v-if="checkout?.lines.length">
      <template v-if="checkout?.lines.length">
        <ul class="my-2">
          <li
            v-for="line in checkout.lines"
            :key="line.id"
            class="flex justify-between gap-16 py-3 [&:not(:last-child)]:border-b"
          >
            <div class="">
              <p class="label ellipsis-2">{{ line.variant.product.name }}</p>
              <span class="text-12 !text-text-main">
                {{ line.variant.product.slug }}
              </span>
            </div>
            <div class="flex flex-col items-end justify-between">
              <span class="label">{{ formatCurrency(line.totalPrice.gross) }}</span>
              <button
                class="-mr-2 rounded-lg p-3 text-error transition-all hover:bg-error/10"
                @click="$emit('delete-line', line.id)"
              >
                <Icon icon="lucide:trash-2" class="h-5 w-5" />
              </button>
            </div>
          </li>
        </ul>
        <Separator />
        <div v-if="checkout" class="mt-5 flex justify-between">
          <p>
            <span class="label">{{ $t("order.subtotal") }}</span>
            <span class="light"> (excl. taxes) </span>
          </p>
          <div class="flex flex-col items-end">
            <span>
              {{
                formatCurrency({
                  amount: checkout?.totalPrice.gross.amount,
                  currency: checkout?.totalPrice.gross.currency,
                })
              }}
            </span>
          </div>
        </div>
      </template>
    </div>
    <p v-else class="text-center">
      {{ $t("order.cart.empty") }}
    </p>
    <NuxtLinkLocale
      v-if="checkout?.lines.length"
      :href="`/checkout?checkoutId=${checkout?.id}`"
      class="label mt-6 inline-block w-full rounded-lg bg-primary p-2.5 text-center text-white disabled:cursor-not-allowed disabled:bg-disabled-light disabled:text-lighter"
      disabled
    >
      {{ $t("order.goToCheckout") }}
    </NuxtLinkLocale>
  </div>
</template>
<script lang="ts" setup>
import type { CartPreviewQuery } from "@graphql";
import { Icon } from "@iconify/vue";

export type checkoutItem = {
  id: string;
  title: string;
  subtitle?: string;
  total: {
    currency: string;
    amount: number;
  };
};

const props = defineProps<{
  checkout: CartPreviewQuery["checkout"];
}>();

const checkout = computed(() => props.checkout);

defineEmits<{
  (e: "delete-line", id: string): void;
}>();

const { formatCurrency } = useFormatCurrency();
</script>
