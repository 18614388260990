/**
 * IANA (Internet Assigned Numbers Authority) - JSON Web Token Claims
 * {@link https://www.iana.org/assignments/jwt/jwt.xhtml}
 */
export type JwtClaims = {
  iat: number;
  iss: string;
  exp: number;
  email: string;
};

export type SaleorJwtClaims = JwtClaims & {
  owner: "saleor";
  token: string;
  type: "access" | "refresh";
  user_id: string;
  is_staff: boolean;
};

export const tryGetExpFromJwt = (token: string): Date | undefined => {
  try {
    const exp = JSON.parse(atob(token.split(".")[1] ?? "")).exp;
    const nowInSeconds = Date.now() / 1000;
    if (exp && typeof exp === "number" && exp > nowInSeconds + 5) {
      return new Date(exp * 1000);
    }
    // eslint-disable-next-line no-empty
  } catch {}
};

/**
 * Implementation from
 * SSR friendly
 * {@link https://stackoverflow.com/a/38552302}
 */
export const parseJwt = <R extends JwtClaims = SaleorJwtClaims>(token: string): R | undefined => {
  let jsonPayload: string | undefined;

  if (typeof window === "undefined") {
    jsonPayload = Buffer.from(token.split(".")[1], "base64").toString();
  } else {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map((c) => `%${("00" + c.charCodeAt(0).toString(16)).slice(-2)}`)
        .join("")
    );
  }

  try {
    return JSON.parse(jsonPayload);
    // eslint-disable-next-line no-empty
  } catch {}

  return undefined;
};
