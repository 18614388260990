export const namedOperations = {
  Query: {
    AddressValidationRules: 'AddressValidationRules',
    UserAddresses: 'UserAddresses',
    CurrentUserOrderList: 'CurrentUserOrderList',
    MenuGetBySlug: 'MenuGetBySlug',
    PageGetBySlug: 'PageGetBySlug',
    ProductDetails: 'ProductDetails',
    ProductList: 'ProductList',
    ProductListByCategory: 'ProductListByCategory',
    ProductListByCollection: 'ProductListByCollection',
    ProductListPaginated: 'ProductListPaginated',
    SearchProducts: 'SearchProducts',
    Categories: 'Categories',
    CartPreview: 'CartPreview',
    ChannelsList: 'ChannelsList',
    CheckoutFind: 'CheckoutFind',
    AvailablePaymentGateways: 'AvailablePaymentGateways',
    DefaultLayout: 'DefaultLayout',
    CurrentUser: 'CurrentUser',
    UserOrders: 'UserOrders',
    UserDetails: 'UserDetails',
    UserGiftCards: 'UserGiftCards'
  },
  Mutation: {
    CreateAddress: 'CreateAddress',
    DeleteAddress: 'DeleteAddress',
    SetDefaultAddress: 'SetDefaultAddress',
    UpdateAddress: 'UpdateAddress',
    CheckoutAddLine: 'CheckoutAddLine',
    CheckoutCreate: 'CheckoutCreate',
    CheckoutDeleteLines: 'CheckoutDeleteLines',
    InitializeTransaction: 'InitializeTransaction',
    ProcessTransaction: 'ProcessTransaction',
    TransactionEventReport: 'TransactionEventReport',
    RegisterUser: 'RegisterUser',
    TokenCreate: 'TokenCreate',
    TokenRefresh: 'TokenRefresh',
    UpdateAccount: 'UpdateAccount'
  },
  Subscription: {
    TransactionProcessSub: 'TransactionProcessSub'
  },
  Fragment: {
    UserAddress: 'UserAddress',
    MenuItem: 'MenuItem',
    OrderDetails: 'OrderDetails',
    ProductListItem: 'ProductListItem',
    UserDetails: 'UserDetails',
    VariantDetails: 'VariantDetails',
    CheckoutDetails: 'CheckoutDetails',
    TransactionDetails: 'TransactionDetails',
    TransactionEventDetails: 'TransactionEventDetails',
    SelectedAttributeList: 'SelectedAttributeList',
    MenuListItem: 'MenuListItem',
    MenuItemCategory: 'MenuItemCategory',
    OrderRow: 'OrderRow'
  }
}