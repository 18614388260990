export const useChannel = () => {
  const { locale: currentLocale, locales } = useI18n();

  const channel = computed<string>(() => {
    const locale = locales.value.find((locale) => locale.code === currentLocale.value);

    return locale?.channel ?? locale?.code;
  });

  return {
    channel,
    // return i18n locale for ease of use
    locale: currentLocale,
  };
};
